
import { mapGetters, mapActions } from 'vuex';
// import FsLightbox from "fslightbox-vue";

export default {
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      rows: 'kindergarten/view/rows',
      current: 'branch/view/branch',
      loading: 'kindergarten/view/loading',
      pagination: 'kindergarten/view/pagination',
    }),
    isDark() {
      return this.$q.dark.isActive;
    },
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    paginationPage() {
      return this.page - 1 || 0;
    },
  },

  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      action: '',
    };
  },

  methods: {
    ...mapActions({
      doFetch: 'kindergarten/view/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
    next() {
      this.page++;
      this.action = 'next';
    },
    prev() {
      this.page--;
      this.action = 'prev';
    },
  },
  watch: {
     current() {
      this.page = 1
       this.doFetch({
        branchId: this.current,
        action: "next",
        currentPage: 0,
      });
     
    },
    async page() {
      this.$router.push({
        path: 'kindergarten',
        query: { page: this.page },
      });
      await this.doFetch({
        branchId: this.current,
        action: this.action,
        currentPage: this.paginationPage,
      });
    },
    '$route.params.page': {
      handler: function () {
        this.page = this.$route.query.page || 1
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.doFetch({
      branchId: this.current,
      action: 'next',
      currentPage: this.paginationPage,
    });

  },
};
